/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 5, 2023 */

@font-face {
  font-family: "lato";
  src: url("lato-regular-webfont.woff2") format("woff2"),
    url("lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "lato";
  src: url("lato-black-webfont.woff2") format("woff2"),
    url("lato-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "lato";
  src: url("lato-blackitalic-webfont.woff2") format("woff2"),
    url("lato-blackitalic-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "lato";
  src: url("lato-bold-webfont.woff2") format("woff2"),
    url("lato-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "lato";
  src: url("lato-bolditalic-webfont.woff2") format("woff2"),
    url("lato-bolditalic-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "lato";
  src: url("lato-italic-webfont.woff2") format("woff2"),
    url("lato-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "lato";
  src: url("lato-light-webfont.woff2") format("woff2"),
    url("lato-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "lato";
  src: url("lato-lightitalic-webfont.woff2") format("woff2"),
    url("lato-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "lato";
  src: url("lato-thin-webfont.woff2") format("woff2"),
    url("lato-thin-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
