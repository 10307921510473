/* Global styles */
body {
  margin: 0;
  padding: 0;
}

* {
  font-family: lato, sans-serif;

  box-sizing: border-box;
}

main {
  margin: auto;
  max-width: 600px;
  width: 100%;
  display: grid;
  grid-template-rows: auto 100px;
  height: 100vh;
}

#chat {
  overflow-y: auto;
  padding: 1rem;
}

.disclaimer {
  margin-top: 15px;
  font-size: 14px;
}

#chat h1 {
  background-clip: text;
  background-image: linear-gradient(#57a01f 0%, #1f6ea0 100%);
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 60px;
  margin: 0;
  font-weight: 900;
  font-family: lato;
}

#chat > h2 {
  color: #57a01f;
  text-align: center;
  font-size: 26px;
  margin: 0.5em;
  font-weight: bold;
  font-family: lato;
}

#chat blockquote {
  color: #666;
  margin: 40px 20px;
  text-align: center;
}

#chat blockquote a:link,
#chat blockquote a:visited {
  color: #666;
}

#input {
  display: grid;
  justify-content: stretch;
  grid-template-columns: min-content auto min-content;
  padding: 8px;
  grid-gap: 5px;
}

#input textarea {
  border: 0;
  border-radius: 0;
  padding: 1rem;
  font-size: 1rem;
  border: 1px #ccc solid;
}

#input button {
  background: none;
  border: 0;
  font-size: 30px;
  cursor: pointer;
}

#input button:hover {
  background: #ccc;
}

#suggestions {
  margin: auto;
  text-align: center;
}

#suggestions button {
  background-color: #9ad8ff;
  color: #000000;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 10px;
  line-height: 26px;
  font-size: 16px;
  flex-grow: 1;
  cursor: pointer;
}

#suggestions button:hover {
  background-color: #6ab8ff;
}
