._1ZkGta_robot {
  align-items: center;
  padding: 10px;
  display: flex;
}

._1ZkGta_robot img {
  margin-right: 10px;
}

._1ZkGta_comment {
  color: #000;
  width: auto;
  background-color: #dcf8c6;
  border-radius: 5px;
  flex-grow: 1;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 26px;
}

._1ZkGta_videoWrapper {
  place-items: center;
  display: grid;
}

.hHpFeW_human {
  align-items: center;
  padding: 10px;
  display: flex;
}

.hHpFeW_human img {
  margin-left: 10px;
}

.hHpFeW_comment {
  color: #000;
  width: auto;
  background-color: #9ad8ff;
  border-radius: 5px;
  flex-grow: 1;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 26px;
}

._6b4n2q_recipeCard {
  border: 1px solid #ccc;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1em;
  padding: 1rem;
  display: flex;
  box-shadow: 0 0 1rem #0003;
}

._6b4n2q_recipeCard section {
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: all .3s ease-in-out;
  overflow: hidden;
}

._6b4n2q_recipeCard section._6b4n2q_showRecipe {
  height: auto;
  padding-bottom: 60px;
  display: flex;
  overflow: visible;
}

._6b4n2q_recipeCard h2 {
  background-color: var(--background);
  color: var(--foreground);
  align-self: stretch;
  margin: -1rem -1rem 1rem;
  padding: 10px 20px;
  font-weight: 900;
}

._6b4n2q_recipeCard p {
  margin: 0 0 1rem;
}

._6b4n2q_recipeCard table {
  border-collapse: collapse;
  align-self: stretch;
}

._6b4n2q_description {
  font-style: italic;
}

._6b4n2q_recipeCard table th, ._6b4n2q_recipeCard table td {
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

._6b4n2q_recipeCard label {
  font-weight: bold;
}

._6b4n2q_recipeCard table th {
  background-color: var(--background);
  color: var(--foreground);
}

._6b4n2q_showRecipeButtonSection {
  background-image: linear-gradient(#ffffff80, #fff);
  justify-content: center;
  align-self: stretch;
  margin-top: -50px;
  padding: 10px;
  display: flex;
}

._6b4n2q_showRecipeButtonSection button {
  background-color: var(--background);
  color: var(--foreground);
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: .25rem;
  align-self: center;
  padding: .5rem 1rem;
  font-size: 1rem;
}

body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  font-family: lato, sans-serif;
}

main {
  max-width: 600px;
  width: 100%;
  height: 100vh;
  grid-template-rows: auto 100px;
  margin: auto;
  display: grid;
}

#chat {
  padding: 1rem;
  overflow-y: auto;
}

.disclaimer {
  margin-top: 15px;
  font-size: 14px;
}

#chat h1 {
  -webkit-text-fill-color: transparent;
  text-align: center;
  background-image: linear-gradient(#57a01f 0%, #1f6ea0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  margin: 0;
  font-family: lato;
  font-size: 60px;
  font-weight: 900;
}

#chat > h2 {
  color: #57a01f;
  text-align: center;
  margin: .5em;
  font-family: lato;
  font-size: 26px;
  font-weight: bold;
}

#chat blockquote {
  color: #666;
  text-align: center;
  margin: 40px 20px;
}

#chat blockquote a:link, #chat blockquote a:visited {
  color: #666;
}

#input {
  grid-gap: 5px;
  grid-template-columns: min-content auto min-content;
  justify-content: stretch;
  padding: 8px;
  display: grid;
}

#input textarea {
  border: 1px solid #ccc;
  border-radius: 0;
  padding: 1rem;
  font-size: 1rem;
}

#input button {
  cursor: pointer;
  background: none;
  border: 0;
  font-size: 30px;
}

#input button:hover {
  background: #ccc;
}

#suggestions {
  text-align: center;
  margin: auto;
}

#suggestions button {
  color: #000;
  cursor: pointer;
  background-color: #9ad8ff;
  border: none;
  border-radius: 5px;
  flex-grow: 1;
  margin: 5px 10px;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 26px;
}

#suggestions button:hover {
  background-color: #6ab8ff;
}

@font-face {
  font-family: lato;
  src: url("lato-regular-webfont.3eb9b0b5.woff2") format("woff2"), url("lato-regular-webfont.73c1d688.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: lato;
  src: url("lato-black-webfont.49a3b306.woff2") format("woff2"), url("lato-black-webfont.d0d8e477.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: lato;
  src: url("lato-blackitalic-webfont.36a96b4c.woff2") format("woff2"), url("lato-blackitalic-webfont.95ed7b6d.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: lato;
  src: url("lato-bold-webfont.d63a5b86.woff2") format("woff2"), url("lato-bold-webfont.bb0511e1.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: lato;
  src: url("lato-bolditalic-webfont.bc3ee893.woff2") format("woff2"), url("lato-bolditalic-webfont.09574b9d.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: lato;
  src: url("lato-italic-webfont.32e6ee54.woff2") format("woff2"), url("lato-italic-webfont.c3e24ad0.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: lato;
  src: url("lato-light-webfont.a98442c9.woff2") format("woff2"), url("lato-light-webfont.80b9bb95.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: lato;
  src: url("lato-lightitalic-webfont.3892fc6c.woff2") format("woff2"), url("lato-lightitalic-webfont.6ec51e89.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: lato;
  src: url("lato-thin-webfont.44eed15a.woff2") format("woff2"), url("lato-thin-webfont.7f881f10.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

/*# sourceMappingURL=index.89d3d93d.css.map */
